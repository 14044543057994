import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
interface ToggleActiveFormBtnProps {
    handleClick: (prev: boolean) => void;
    switchForm: boolean;
}
const ToggleActiveFormBtn: FunctionComponent<ToggleActiveFormBtnProps> = ({
    switchForm,
    handleClick,
}: ToggleActiveFormBtnProps) => {
    const { t } = useTranslation(['f', 'b']);

    return (
        <div className="col-md-12 d-flex align-items-center mb-10 mt-30 f-wrap">
            <h1
                className={`contact-form-modal__title ${switchForm && 'contact-form-modal__title--active'} mr-10`}
                onClick={() => {
                    handleClick(true);
                }}
            >
                {t('f:contactForm.requestDemo')}
            </h1>
            <h1 className="contact-form-modal__title mr-10">{t('f:contactForm.or')}</h1>
            <h1
                className={`contact-form-modal__title ${!switchForm && 'contact-form-modal__title--active'}`}
                onClick={() => {
                    handleClick(false);
                }}
            >
                {t('f:contactForm.contactUs')}
            </h1>
        </div>
    );
};
export default ToggleActiveFormBtn;
