import * as Yup from 'yup';

export const ResearchContactFormValidationSchemaFirst = Yup.object().shape({
    email: Yup.string().email('Email address').required('Required'),
    firstName: Yup.string().required('Required'),
    company: Yup.string().required('Required'),
    phone: Yup.number().required('Required'),
    terms: Yup.bool().required('Required').oneOf([true], 'Field must be checked'),
});
export const initialFirst = {
    email: '',
    firstName: '',
    phone: '',
    company: '',
    country: '',
    terms: false,
};

export const ResearchContactFormValidationSchemaSecond = Yup.object().shape({
    email: Yup.string().email('Email address').required('Required'),
    firstName: Yup.string().required('Required'),
    company: Yup.string().required('Required'),
    message: Yup.string().required('Required'),
});

export const initialSecond = {
    email: '',
    firstName: '',
    message: '',
    country: '',
};
