import { FunctionComponent, useContext } from 'react';
import ContactFormState from '../../context/state/ContactFormState';

const CloseBtn: FunctionComponent = () => {
    const { setIsContectFormOpen } = useContext(ContactFormState);
    return (
        <div className="contact-form-modal__close" onClick={() => setIsContectFormOpen(false)}>
            <p>x</p>
        </div>
    );
};

export default CloseBtn;
