import * as React from 'react';

export type ContactFormState = {
    isContectFormOpen: boolean;
    setIsContectFormOpen: (prev: boolean) => void;
};

export default React.createContext<ContactFormState>({
    isContectFormOpen: false,
    setIsContectFormOpen: (prev: boolean) => {},
});
