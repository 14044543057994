import { FunctionComponent } from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

interface ContactFormInputProps {
    placeholder: string;
    errors: any;
    touched: any;
    label: string;
    fieldName: string;
}

const ContactFormInput: FunctionComponent<ContactFormInputProps> = ({
    placeholder,
    errors,
    fieldName,
    touched,
}: ContactFormInputProps) => {
    const { t } = useTranslation(['f', 'b']);

    const hasErrors = (errors: any, name: string, touched: any) => {
        if (errors && errors[name] && touched[name]) {
            return <span className="error-text">{t(`${errors[name]}`, { name: t(`f:research.${name}`) })}</span>;
        }
    };
    return (
        <div className="col-xs-12 form-group">
            <Field id={fieldName} className="contact-form-modal__input" name={fieldName} placeholder={placeholder} />
            {hasErrors(errors, fieldName, touched)}
        </div>
    );
};

export default ContactFormInput;
