import { FunctionComponent, useContext } from 'react';
import darkLogo from '../../assets/images/logo.png';
import { useEffect, useState } from 'react';
import lightLogo from '../../assets/images/logo-w.png';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SlideInMenu from './SlideInMenu';
import ContactFormState from '../../context/state/ContactFormState';
import CloseBtn from '../shared/CloseBtn';

const lightPages = ['/', '/data-link-or-transaction'];

const MainMenu: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory(),
        [openSlideInMenu, setOpenSlideInMenu] = useState<boolean>(false);

    return (
        <nav className="d-flex">
            <div className="navigation__lg-menu">
                <button
                    onClick={() => {
                        history.push(`/data-link-or-transaction#10`);
                    }}
                    className="navigation__top-links"
                >
                    {t('f:menu.datalink')}
                </button>
                <span className="navigation__line ml-30"></span>
                <button
                    onClick={() => {
                        history.push(`/data-link-or-transaction#20`);
                    }}
                    className="navigation__top-links"
                >
                    {t('f:menu.transaction')}
                </button>
            </div>
            <div
                className="navigation__burger"
                onClick={() => {
                    setOpenSlideInMenu(true);
                }}
            >
                <div className="navigation__burger-line"></div>
                <div className="navigation__burger-line"></div>
                <div className="navigation__burger-line"></div>
            </div>
            <SlideInMenu openSlideInMenu={openSlideInMenu} setOpenSlideInMenu={setOpenSlideInMenu} />
        </nav>
    );
};

const Header: FunctionComponent = () => {
    // Add pages for light header
    const [firstHalf, setFistHalf] = useState<Array<string>>(['/add-property']),
        { isContectFormOpen } = useContext(ContactFormState),
        history = useHistory(),
        location = useLocation();

    const isLightHeader = (): Boolean => {
        return lightPages.some((page) => location.pathname === page);
    };

    const isFirstHakfLightHeader = (): Boolean => {
        return firstHalf.some((page) => location.pathname === page);
    };

    useEffect(() => {
        const id = location.pathname.slice(12);
        if (Number(id)) {
            const half = [...firstHalf];
            half.push(`/bid-wizard/${id}`);
            setFistHalf(half);
        }
    }, [location.pathname, firstHalf]);

    return (
        <header>
            <div className={`row-flex-clear navigation__container ${isLightHeader() ? 'light' : ''}`}>
                <figure className="logo">
                    <button
                        onClick={() => {
                            history.push('/');
                        }}
                    >
                        <img src={isLightHeader() || isFirstHakfLightHeader() ? lightLogo : darkLogo} alt="Logo" />
                    </button>
                </figure>
                {isContectFormOpen ? <CloseBtn /> : <MainMenu />}
            </div>
        </header>
    );
};

export default Header;
