import api from '..';

const featchCountries = () => {
    return api.get(`/country`);
};

const APIs = {
    featchCountries,
};

export default APIs;
