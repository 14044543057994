import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import HomeButton from '../../../components/shared/HomeButton';
import ContactFormState from '../../../context/state/ContactFormState';

interface Props {
    title: string;
    subtitle: string;
    params: string;
    video: string;
}

const Header: FunctionComponent<Props> = ({ params, title, subtitle, video }) => {
    const { t } = useTranslation(['f', 'b']),
        { setIsContectFormOpen } = useContext(ContactFormState);

    const handleOnClickOpenModal = useCallback(() => {
        setIsContectFormOpen(true);
    }, [setIsContectFormOpen]);

    return (
        <div className={`dlt-header-page dlt-header-page--bg-color`}>
            <div className="mx-50 row-flex-clear align-items-center dlt-header-page__full-height">
                <div className="col-xs-12 col-lg-4 dlt-header-page__main--data-link">
                    <h1 className="dlt-header-page__title">{title}</h1>
                    <p className="dlt-header-page__subtitle mt-40 mb-100">{subtitle}</p>
                    <HomeButton
                        classes="navigation__top-btn navigation__top-btn--small"
                        text={t('f:menu.requestDemo')}
                        handler={handleOnClickOpenModal}
                    />
                </div>
                <video src={video} autoPlay muted className="col-xs-12 col-lg-8" />
            </div>
        </div>
    );
};

export default Header;
