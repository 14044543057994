import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HomeButton from '../../../components/shared/HomeButton';
import { text } from '../../../constants/HomePageText';
import ContactFormState from '../../../context/state/ContactFormState';
import useBoolean from '../../../hooks/use-boolean';
import PopVideoModal from './PopVideoModal';

const Header: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        [openVideoModal, setVideoModalActions] = useBoolean(),
        { setIsContectFormOpen } = useContext(ContactFormState);

    const handleOnClickOpenModal = useCallback(() => {
        setIsContectFormOpen(true);
    }, [setIsContectFormOpen]);

    const onViewDemoClick = useCallback(() => {
        window.open('https://app.virgate.com/property/75d41533-baf1-47fc-85b3-efcbf862929c', '_blank');
    }, []);

    useEffect(() => {
        var i = 0;
        const div: any = document.getElementById('roll-me');
        const h1 = document.createElement('h1');
        h1.classList.add('home-page-header__rolling-text');

        function myLoop() {
            div.innerHTML = '';
            h1.innerText = '';
            h1.innerText = `${text[i]}`;
            div.append(h1);
            setTimeout(function () {
                i++;
                if (i === text.length - 1) {
                    i = 0;
                    myLoop();
                } else {
                    myLoop();
                }
            }, 9000);
        }
        window.addEventListener('load', myLoop);
        return () => window.removeEventListener('load', myLoop);
    }, []);

    return (
        <div className="home-page-header__main d-flex flex-direction-column justify-content-center align-items-center">
            <div className="home-page-header__rolling-text-container mb-120" id="roll-me"></div>
            {/* <HomeButton
                classes="navigation__top-btn navigation__top-btn--small mt-30"
                text={t('f:menu.requestDemo')}
                handler={handleOnClickOpenModal}
            /> */}

            <HomeButton
                classes="navigation__top-btn navigation__top-btn--small mt-30"
                text={t('f:menu.viewDemo')}
                handler={onViewDemoClick}
            />

            <p
                className="d-flex align-items-center navigation__top-btn navigation__top-btn--small navigation__top-btn--no-border"
                onClick={() => {
                    setVideoModalActions.setTrue();
                }}
            >
                {t('f:menu.whyVirgate')} <FontAwesomeIcon className="ml-20" icon={faPlay} />
            </p>
            {openVideoModal && <PopVideoModal setIsModalOpened={setVideoModalActions.toggle} />}
        </div>
    );
};

export default Header;
