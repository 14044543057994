import React, { FunctionComponent } from 'react';
import useBoolean from '../hooks/use-boolean';
import ContactFormState from './state/ContactFormState';

interface EmailContextProps {
    children: React.ReactNode;
}

const ContactModalContext: FunctionComponent<EmailContextProps> = ({ children }: EmailContextProps) => {
    const [isContectFormOpen, isContectFormOpenActions] = useBoolean();

    return (
        <ContactFormState.Provider value={{ isContectFormOpen, setIsContectFormOpen: isContectFormOpenActions.toggle }}>
            {children}
        </ContactFormState.Provider>
    );
};

export default ContactModalContext;
