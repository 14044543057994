import { FunctionComponent } from 'react';

interface HomeButtonProps {
    classes: string;
    text: string;
    handler: () => void;
}

const HomeButton: FunctionComponent<HomeButtonProps> = ({ classes, text, handler }: HomeButtonProps) => (
    <button className={classes} onClick={handler}>
        {text}
    </button>
);
export default HomeButton;
