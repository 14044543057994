import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ContactFormState from '../../context/state/ContactFormState';
import CloseBtn from '../shared/CloseBtn';

interface SlideInMenuProps {
    openSlideInMenu: boolean;
    setOpenSlideInMenu: (prev: boolean) => void;
}

const SlideInMenu = ({ openSlideInMenu, setOpenSlideInMenu }: SlideInMenuProps) => {
    const { t } = useTranslation(['f', 'b']),
        { setIsContectFormOpen } = useContext(ContactFormState);

    const handleOnClickClose = () => {
        setOpenSlideInMenu(false);
    };

    return (
        <div className={`slide-in-menu${openSlideInMenu ? ' slide-in-menu--slide' : ''}`}>
            <CloseBtn />

            <ul className="mt-50">
                <li className="d-flex justify-content-center">
                    <a
                        href="/data-link-or-transaction#10"
                        className="navigation__top-links navigation__top-links--slide"
                        onClick={handleOnClickClose}
                    >
                        {t('f:menu.datalink')}
                    </a>
                </li>
                <li className="d-flex justify-content-center mt-20">
                    <a
                        href="/data-link-or-transaction#20"
                        className="navigation__top-links navigation__top-links--slide"
                        onClick={handleOnClickClose}
                    >
                        {t('f:menu.transaction')}
                    </a>
                </li>
                <li className="d-flex justify-content-center mt-20">
                    <button
                        className="navigation__top-btn"
                        onClick={() => {
                            handleOnClickClose();
                            setIsContectFormOpen(true);
                        }}
                    >
                        {t('f:menu.requestDemo')}
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default SlideInMenu;
