import { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import loader from '../../assets/videos/loader.mp4';

interface MainLoaderProps {
    type: 'main' | 'regular';
    size?: number;
    mb?: 'mb-10' | 'mb-20' | 'mb-30' | 'mb-40' | 'mb-50' | 'mb-60' | 'mb-70' | 'mb-80' | 'mb-90' | 'mb-100' | 'mt-200';
    mt?: 'mt-10' | 'mt-20' | 'mt-30' | 'mt-40' | 'mt-50' | 'mt-60' | 'mt-70' | 'mt-80' | 'mt-90' | 'mt-100' | 'mt-200';
}

const MainLoader: FunctionComponent<MainLoaderProps> = ({ size, type, mb, mt }: MainLoaderProps) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const checkType = useCallback(() => {
        switch (type) {
            case 'regular':
                return 'd-flex align-items-center justify-content-center';
            default:
                return 'main-loader';
        }
    }, [type]);
    useEffect(() => {
        if (videoRef && videoRef.current) videoRef.current.playbackRate = 2.0;
    }, [videoRef]);

    return (
        <div className={`${checkType()} ${mt} ${mb}`}>
            <video
                ref={videoRef}
                src={loader}
                autoPlay
                loop
                muted
                style={{ height: size ? `${size}px` : '200px', width: size ? `${size}px` : '200px' }}
            />
        </div>
    );
};
export default MainLoader;
