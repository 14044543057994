import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';
import homePageVideo from '../../../assets/videos/home-page-video.mp4';

interface PopVideoModalProps {
    setIsModalOpened: (boolean: boolean) => void;
}

const PopVideoModal: FunctionComponent<PopVideoModalProps> = ({ setIsModalOpened }: PopVideoModalProps) => (
    <div className="home-page-about__video-container d-flex align-items-center justify-content-center">
        <div className="home-page-about__video-container-black d-flex flex-direction-column">
            <FontAwesomeIcon
                icon={faTimes}
                className="home-page-about__cross"
                onClick={() => {
                    setIsModalOpened(false);
                }}
            />

            <video className="home-page-about__video-container-video" src={homePageVideo} controls autoPlay></video>
        </div>
    </div>
);

export default PopVideoModal;
