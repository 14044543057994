import CountryApi from '../api/routes/CountryApi';

const featchCountries = async () => {
    const { data } = await CountryApi.featchCountries();
    console.log(data);

    return data;
};

const Service = {
    featchCountries,
};

export default Service;
