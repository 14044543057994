import Routes from '../../routes/routes';
import Header from './Header';
import { FunctionComponent } from 'react';
import Footer from '../shared/Footer';
import { useLocation } from 'react-router';
import ContactModalContext from '../../context/ContactModalContext';
import ContactFormModal from '../../components/shared/ContactForm';

const componetWithoutFooter = ['', '/'];

const Layout: FunctionComponent<any> = () => {
    const location = useLocation();

    const checkForFooter = () => {
        return !componetWithoutFooter.some((page) => location.pathname === page);
    };

    return (
        <ContactModalContext>
            <Header />
            <Routes />
            {checkForFooter() && <Footer />}
            <ContactFormModal />
        </ContactModalContext>
    );
};

export default Layout;
