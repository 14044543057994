import PropertyAssetHomePageApi from '../api/routes/PropertyAssetHomePageApi';

const fetchProperty = async (quantity: number) => {
    const { data } = await PropertyAssetHomePageApi.fetchProperty(quantity);

    return data;
};

const Service = { fetchProperty };
export default Service;
