import { useCallback, useEffect, useState } from 'react';
import PropertyAssetHomePage from '../services/PropertyAssetHomePage';
import PropertyAsserts from '../interfaces/property/PropertyAsserts';
import PropertyCard from '../components/partials/PropertyCard';
import useBoolean from '../hooks/use-boolean';
import MainLoader from './shared/MainLoader';

const quantity = 6;

interface PropertyPersistData {
    createDate: Date;
    items: Array<PropertyAsserts>;
}

export const PropertyContainer = () => {
    const [property, setProperty] = useState<Array<PropertyAsserts>>([]),
        [isLoaded, isLoadedActions] = useBoolean();

    const setData = useCallback(
        (items: Array<PropertyAsserts>) => {
            setProperty(items);

            isLoadedActions.setTrue();
        },
        [isLoadedActions]
    );

    const fetchProperty = useCallback(async () => {
        try {
            const searchResult = await PropertyAssetHomePage.fetchProperty(quantity);

            localStorage.setItem('Property', JSON.stringify({ createDate: new Date(), items: searchResult }));
            setData(searchResult);
        } catch (error: any) {
            console.log(error);
        }
    }, [setData]);

    const checkIfAssetsAreNeed = useCallback(
        (data: PropertyPersistData) => {
            const createDate: number = new Date(data.createDate).getTime();
            const now: number = new Date().getTime();

            const dataAge = Math.round((now - createDate) / (1000 * 24));
            const tooOld = dataAge >= 3600;

            tooOld ? fetchProperty() : setData(data.items);
        },
        [fetchProperty, setData]
    );

    useEffect(() => {
        const data = localStorage.getItem('Property');

        data ? checkIfAssetsAreNeed(JSON.parse(data)) : fetchProperty();
    }, [checkIfAssetsAreNeed, fetchProperty]);

    return (
        <>
            {isLoaded ? (
                <div className="row-clear property-card__big-conteiner d-flex f-wrap">
                    {property.map((element: any, index: number) => {
                        return (
                            <div className="col-xs-12-clear col-sm-6-clear col-lg-4-clear" key={index}>
                                <PropertyCard property={element} key={element.id} />
                            </div>
                        );
                    })}
                </div>
            ) : (
                <MainLoader type="regular" mb="mb-100" />
            )}
        </>
    );
};
export default PropertyContainer;
