import { Formik, Form, Field } from 'formik';
import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ResearchContactFormValidationSchemaFirst,
    ResearchContactFormValidationSchemaSecond,
    initialFirst,
    initialSecond,
} from '../../validations/ResearchContactForm';
import ContactForm from '../../services/ContactForm';
import { Select } from 'antd';
import CountryService from '../../services/Country';
import { Country } from '../../interfaces/property/Country';
import useBoolean from '../../hooks/use-boolean';
import ToggleActiveFormHeader from '../partials/ToggleActiveFormHeader';
import ContactFormInput from './ContactFormInput';
import ContactFormState from '../../context/state/ContactFormState';
import CloseBtn from './CloseBtn';
import MainLogo from './MainLogo';

const { Option } = Select;

const ContactFormModal: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        [isFormShown, isFormShownActions] = useBoolean(true),
        { isContectFormOpen, setIsContectFormOpen } = useContext(ContactFormState),
        [switchForm, setSwitchFormActions] = useBoolean(true),
        [isError, isErrorActions] = useBoolean(),
        [countries, setCountries] = useState<Array<Country>>([]);

    const hasErrors = (errors: any, name: string, touched: any) => {
        if (errors && errors[name] && touched[name]) {
            return <span className="error-text">{t(`${errors[name]}`, { name: t(`f:research.${name}`) })}</span>;
        }
    };

    const closeForm = () => {
        setTimeout(() => {
            setIsContectFormOpen(false);
            isFormShownActions.setTrue();
        }, 2000);
    };

    const fetchCountries = useCallback(async () => {
        const data = await CountryService.featchCountries();
        setCountries(data);
    }, []);

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries]);

    return (
        <div className={`contact-form-modal${isContectFormOpen ? ' contact-form-modal--slide' : ''}`}>
            <MainLogo />
            <CloseBtn />

            <div className="contact-form-modal__main d-flex">
                {!isFormShown && (
                    <>
                        {isError ? (
                            <div className="contact-form-modal__right contact-form-modal__right--pop-up col-xs-12">
                                <div className="contact-form-modal__left col-xs-12">
                                    <div className="d-flex align-items-center justify-content-center mb-40">
                                        <p className="color-red">{t('f:contactForm.error')}</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="contact-form-modal__right contact-form-modal__right--pop-up col-xs-12">
                                <div className="contact-form-modal__left col-xs-12">
                                    <div className="mt-20 mb-20 d-flex flex-direction-column align-items-center justify-content-center">
                                        <p className="color-white">{t('f:contactForm.successFirst')}</p>
                                        <p className="color-white mt-10">{t('f:contactForm.successSecond')}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {isFormShown && (
                    <>
                        <div className="contact-form-modal__right col-xs-12">
                            <Formik
                                initialValues={switchForm ? initialFirst : initialSecond}
                                validationSchema={
                                    switchForm
                                        ? ResearchContactFormValidationSchemaFirst
                                        : ResearchContactFormValidationSchemaSecond
                                }
                                enableReinitialize
                                onSubmit={async (values: any, { resetForm }) => {
                                    await ContactForm.contactForm({
                                        firstName: values.firstName,
                                        lastName: values.lastName,
                                        message: 'Request a demo ',
                                        fromEmail: values.email,
                                    })
                                        .then(() => {
                                            isFormShownActions.setFalse();
                                            isErrorActions.setFalse();
                                            closeForm();
                                        })
                                        .catch(() => {
                                            isFormShownActions.setFalse();
                                            isErrorActions.setTrue();
                                            closeForm();
                                        });
                                }}
                            >
                                {({ resetForm, errors, touched, validateForm, setFieldValue, values }) => (
                                    <Form className="row mt-50">
                                        <ToggleActiveFormHeader
                                            switchForm={switchForm}
                                            handleClick={setSwitchFormActions.toggle}
                                        />
                                        <ContactFormInput
                                            errors={errors}
                                            touched={touched}
                                            fieldName="firstName"
                                            label={t('f:contactForm.fullName')}
                                            placeholder={t('f:contactForm.fullName')}
                                        />
                                        <ContactFormInput
                                            errors={errors}
                                            touched={touched}
                                            fieldName="email"
                                            label={t('f:contactForm.email')}
                                            placeholder={t('f:contactForm.email')}
                                        />

                                        {switchForm && (
                                            <ContactFormInput
                                                errors={errors}
                                                touched={touched}
                                                fieldName="phone"
                                                label={t('f:contactForm.phoneNumber')}
                                                placeholder={t('f:contactForm.phoneNumber')}
                                            />
                                        )}

                                        {!switchForm && (
                                            <div className="col-xs-12 form-group">
                                                <Field
                                                    cols="4"
                                                    className="contact-form-modal__input contact-form-modal__input--textarea"
                                                    name="message"
                                                    component="textarea"
                                                    placeholder={t('f:contactForm.message')}
                                                />
                                                {hasErrors(errors, 'message', touched)}
                                            </div>
                                        )}
                                        {switchForm && (
                                            <ContactFormInput
                                                errors={errors}
                                                touched={touched}
                                                fieldName="company"
                                                label={t('f:contactForm.company')}
                                                placeholder={t('f:contactForm.company')}
                                            />
                                        )}
                                        <div className="col-xs-12 form-group">
                                            <Select
                                                placeholder={t('f:contactForm.country')}
                                                className="contact-form-modal__select"
                                            >
                                                {countries.map((e) => (
                                                    <Option
                                                        value={e.id}
                                                        onChange={() => {
                                                            setFieldValue('country', e.id);
                                                        }}
                                                    >
                                                        {e.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                            {hasErrors(errors, 'country', touched)}
                                        </div>
                                        <div className="col-xs-12 form-group">
                                            <label
                                                htmlFor="terms"
                                                className="d-flex align-items-center  checkbox text-rubik-16 f-wrap"
                                            >
                                                <input
                                                    className="mr-15 select-checkbox"
                                                    type="checkbox"
                                                    name="terms"
                                                    checked={values.terms}
                                                    onChange={() => {
                                                        setFieldValue('terms', !values.terms);
                                                    }}
                                                />
                                                <span className="contact-form-modal__terms">
                                                    {t('f:contactForm.termsFrist')}
                                                    <a href="/terms-of-use" className="contact-form-modal__terms">
                                                        <strong>{t('f:contactForm.terms')} </strong>
                                                    </a>
                                                </span>
                                            </label>
                                            {hasErrors(errors, 'terms', touched)}
                                        </div>
                                        <div className="col-md-12 m-auto mt-30 mb-30">
                                            <button
                                                className="button-small button-secondary--blue"
                                                type="submit"
                                                onClick={validateForm}
                                            >
                                                {t('f:button.submit')}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ContactFormModal;
