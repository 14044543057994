import api from '..';

const fetchProperty = (quantity: number) => {
    return api.get(`/propertyassethomepage?quantity=${quantity}`);
};

const APIs = {
    fetchProperty,
};

export default APIs;
