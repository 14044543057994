import { FunctionComponent, useEffect, useRef, useState } from 'react';
import Header from './Header';
import { useTranslation } from 'react-i18next';

const mainVideo = `https://virgatedev.blob.core.windows.net/property/header-video_1637579099.mp4`;

const Hero: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        videoParentRef = useRef<any>(),
        [shouldUseImage, setShouldUseImage] = useState(false);

    const isSafari = () => {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf('safari') > -1 && ua.indexOf('chrome') < 0;
    };

    useEffect(() => {
        if (isSafari() && videoParentRef.current) {
            const player = videoParentRef.current.children[0];

            if (player) {
                player.controls = false;
                player.playsinline = true;
                player.muted = true;
                player.setAttribute('muted', ''); // leave no stones unturned :)
                player.autoplay = true;

                setTimeout(() => {
                    const promise = player.play();
                    if (promise.then) {
                        promise
                            .then(() => {})
                            .catch(() => {
                                videoParentRef.current.style.display = 'none';
                                setShouldUseImage(true);
                            });
                    }
                }, 0);
            }
        }
    }, []);

    return (
        <div className="home-page-header d-flex flex-direction-center justify-content-center align-items-center">
            <Header />
            {shouldUseImage ? (
                <img src={mainVideo} alt="Muted Video" />
            ) : (
                <div
                    className="home-page-header__video"
                    ref={videoParentRef}
                    dangerouslySetInnerHTML={{
                        __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
          
        >
        <source src="${mainVideo}" type="video/mp4" />
        </video>`,
                    }}
                />
            )}
            <div className="home-page-header__terms">
                <div className="d-flex">
                    <a href="/terms-of-use" className="home-page-header__terms-links mr-30">
                        {t(`f:footer.termsOfUse`)}
                    </a>
                    <a href="/privacy-policy" className="home-page-header__terms-links">
                        {t(`f:footer.privacyPolicy`)}
                    </a>
                </div>
            </div>
        </div>
    );
};
export default Hero;
