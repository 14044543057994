import { useMemo, useCallback, useState } from 'react';

interface Actions {
  setTrue: () => void;
  setFalse: () => void;
  toggle: (value?: boolean | undefined) => void;
}

const useBoolean = (initialState = false): [boolean, Actions] => {
  const [state, setState] = useState(initialState);

  const setTrue = useCallback(() => setState(true), [setState]);
  const setFalse = useCallback(() => setState(false), [setState]);
  const toggle = useCallback(() => setState((s) => !s), [setState]);

  return useMemo(() => [state, { setTrue, setFalse, toggle }], [state, setTrue, setFalse, toggle]);
};

export default useBoolean;
